<template>
    <div v-if="accountGroups.length">
        <styled-tooltip
            position="top">
            <template #content>
                <ul>
                    <li
                        v-for="(group, index) in accountGroups"
                        :key="index">
                        <span>{{ group.name }}</span>
                    </li>
                </ul>
            </template>
            <span
                class="text-link"
                href="#"
                @click.prevent="() => false">
                {{ accountGroups.length }}
            </span>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        accountGroups() {
            return this.item.account_groups?.data ?? [];
        },
    }
};
</script>
